var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","xl":"12"}},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'inventoryList' })}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('inventory-card',{staticClass:"ma-0",attrs:{"inventory":_vm.inventory},on:{"update":_vm.fetchInventory}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12"}},[_c('MoveView',{ref:"move",staticClass:"ma-0",attrs:{"inventory":_vm.inventory}},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",staticStyle:{"height":"30px"},attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("inventorymovement.new"))+" ")])],1)]}}],null,false,1577837606),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("inventorys.new_movement")))]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('inventorymovements-form',{ref:"form",attrs:{"title":"view","inventorymovement":_vm.inventorymovement,"productId":_vm.inventorymovement.product_id,"action":_vm.action},on:{"cancel":function($event){_vm.dialog = false},"refreshTable":function($event){_vm.refresh();
                      _vm.fetchInventory();
                      _vm.dialog = false;}}})],1)],1)],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"6"}},[_c('SupplierView',{attrs:{"action":"view","inventory":_vm.inventory},on:{"update":function($event){return _vm.fetchInventory()}}})],1),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12"}},[_c('MoveView',{ref:"move",staticClass:"ma-0",attrs:{"inventory":_vm.inventory}},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var dialog = ref.on;
                      var attrs = ref.attrs;
return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",staticStyle:{"height":"30px"},attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("inventorymovement.new"))+" ")])],1)]}}],null,false,1577837606),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("inventorys.new_movement")))]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('inventorymovements-form',{ref:"form",attrs:{"title":"view","inventorymovement":_vm.inventorymovement,"productId":_vm.inventorymovement.product_id,"action":_vm.action},on:{"cancel":function($event){_vm.dialog = false},"refreshTable":function($event){_vm.refresh();
                  _vm.fetchInventory();
                  _vm.dialog = false;}}})],1)],1)],1)],1):_vm._e()],1),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"7","xl":"8"}},[_c('inventorymovements-table',{ref:"inventorymovementsTable",attrs:{"outlined":"","title":_vm.$t('movimientos producto'),"height":"140px"},on:{"delete":function($event){_vm.refresh();
            _vm.fetchInventory();}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }