<template>
  <v-container fluid>
    <base-material-card>
      <v-row>
        <v-col cols="12" md="12" xl="12">
          <v-btn
            color="primary"
            @click.native="$router.push({ name: 'inventoryList' })"
            style="height: 30px"
            outlined
            v-if="!$vuetify.breakpoint.xsOnly"
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t("back") }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="6" xl="6">
          <v-row
            ><v-col cols="12"
              ><inventory-card
                class="ma-0"
                :inventory="inventory"
                @update="fetchInventory"
              ></inventory-card>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12">
              <MoveView ref="move" class="ma-0" :inventory="inventory">
                <v-dialog v-model="dialog" width="400">
                  <template v-slot:activator="{ on: dialog, attrs }">
                    <v-row justify="end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                            color="primary"
                            style="height: 30px"
                            class="mr-3"
                            icon
                            v-bind="attrs"
                            v-on="{ ...dialog, ...tooltip }"
                          >
                            <v-icon size="36">mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("inventorymovement.new") }}
                      </v-tooltip>
                    </v-row>
                  </template>
                  <v-card>
                    <v-card-title>{{
                      $t("inventorys.new_movement")
                    }}</v-card-title>
                    <div class="close">
                      <v-btn icon @click="dialog = false" small>
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <inventorymovements-form
                      title="view"
                      ref="form"
                      @cancel="dialog = false"
                      :inventorymovement="inventorymovement"
                      :productId="inventorymovement.product_id"
                      :action="action"
                      @refreshTable="
                        refresh();
                        fetchInventory();
                        dialog = false;
                      "
                    />
                  </v-card> </v-dialog
              ></MoveView>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6" xl="6">
          <SupplierView
            action="view"
            @update="fetchInventory()"
            :inventory="inventory"
          ></SupplierView>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="12">
          <MoveView ref="move" class="ma-0" :inventory="inventory">
            <v-dialog v-model="dialog" width="400">
              <template v-slot:activator="{ on: dialog, attrs }">
                <v-row justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        color="primary"
                        style="height: 30px"
                        class="mr-3"
                        icon
                        v-bind="attrs"
                        v-on="{ ...dialog, ...tooltip }"
                      >
                        <v-icon size="36">mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    {{ $t("inventorymovement.new") }}
                  </v-tooltip>
                </v-row>
              </template>
              <v-card>
                <v-card-title>{{ $t("inventorys.new_movement") }}</v-card-title>
                <div class="close">
                  <v-btn icon @click="dialog = false" small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <inventorymovements-form
                  title="view"
                  ref="form"
                  @cancel="dialog = false"
                  :inventorymovement="inventorymovement"
                  :productId="inventorymovement.product_id"
                  :action="action"
                  @refreshTable="
                    refresh();
                    fetchInventory();
                    dialog = false;
                  "
                />
              </v-card> </v-dialog
          ></MoveView>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="12" md="7" xl="8">
          <inventorymovements-table
            outlined
            ref="inventorymovementsTable"
            :title="$t('movimientos producto')"
            height="140px"
            @delete="
              refresh();
              fetchInventory();
            "
          ></inventorymovements-table>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "InventoryView",
  data() {
    return {
      inventory: {},
      items: [],
      options: {},
      totalPrice: 0,
      moveView: {},
      dialog: false,
      items1: [],
      inventorymovement: {},
      action: "save",
    };
  },
  components: {
    InventoryCard: () => import("@/components/inventory/Card"),
    InventorymovementsTable: () =>
      import("@/components/inventorymovement/Table"),
    MoveView: () => import("@/components/inventorymovement/MoveView"),
    SupplierView: () => import("@/components/inventorymovement/SupplierView"),
    InventorymovementsForm: () => import("@/components/inventorymovement/Form"),
  },
  watch: {
    options: {
      handler() {
        this.fetchInventorys();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchInventory();
  },
  methods: {
    ...mapActions("inventory", ["getInventory"]),
    fetchInventory() {
      this.getInventory(this.$route.params.id).then((inventory) => {
        this.inventory = inventory;
        this.inventorymovement.product_cody = this.inventory.product_cody;
        this.inventorymovement.description = this.inventory.description;
        this.inventorymovement.price = this.inventory.product_price;
        this.inventorymovement.product_id = this.inventory.id;
        this.refresh();
        //console.log(this.inventory);
      });
    },
    refresh() {
      setTimeout(() => {
        this.$refs.inventorymovementsTable.fetchInventorymovements();
        this.$refs.move.fetchInventorymovements();
      }, 1000);
    },
    openForm() {
      this.$form.open();
    },
  },
  computed: {},
};
</script>

<style scoped lang="sass">
.v-data-table
  td
    padding: 3px 8px !important
  td:first-child
    color: var(--v-gris1-base)
</style>
